import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

const Search = () => {
  return (
    <>
      <section className="leads">
        <Container fluid>
          <Row>
            <Col sm="12">
              <Col className="d-flex align-items-center mb-4">
                <Link to={"/home"} className="btn btn-back">
                  <i class="bi bi-chevron-left"></i>
                </Link>
                <h5 className="page-title">Search</h5>
              </Col>
                <Form.Group className="mb-3 mt-5">
                  <Form.Control type="text" placeholder="Enter Name Email or Cell" />
                </Form.Group>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Search;
