import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

export default function LoginPage() {
  return (
    <>
      <section className="login-screen">
        <Container fluid>
          <Row>
            <Col sm="12" className="text-center">
              <Link to={"/"}>
                <Image src="images/logo.png" className="logo" alt="logo" />
              </Link>

              <h2>Login</h2>
              <Form>
                <Form.Group className="mb-4">
                  <Form.Control type="text" placeholder="User Name" />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Control type="password" placeholder="Password" />
                </Form.Group>
                <Link to={"/home"} className="btn-red">
                  Login
                </Link>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
