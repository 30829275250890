import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

const CloseLostDeal = () => {
  return (
    <>
      <section className="leads">
        <Container fluid>
          <Row>
            <Col sm="12">
              <Col className="d-flex align-items-center mb-4">
                <Link to={"/resulting-in"} className="btn btn-back">
                  <i class="bi bi-chevron-left"></i>
                </Link>
              
              </Col>
              <Form.Group className="mb-5 mt-5">
                <Form.Label>Why didn’t they buy from us?</Form.Label>
                <Form.Select className="form-control">
                  <option selected>-- Please Select --</option>
                  <option value="1">
                    Contact the customer again in 6 months{" "}
                  </option>
                  <option value="2">
                    Contact the customer again in 12 months
                  </option>
                  <option value="3">
                    Contact the customer again in 18 months
                  </option>
                  <option value="4">
                    Contact the customer again in 24 months
                  </option>
                  <option value="5">
                    Contact the customer again in 36 months
                  </option>
                </Form.Select>
              </Form.Group>
              <Link to={"#"} className="btn btn-red blue">Save</Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default CloseLostDeal;
