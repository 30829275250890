import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <section className="dashboard">
        <Container fluid>
          <Row>
            <Col sm="12" className="text-center">
              <Link to={"/"}>
                <Image
                  src="images/dealer-logo.png"
                  className="logo"
                  alt="logo"
                />
              </Link>
              <h5>Appristine</h5>
              <small>Darshan</small>
              <h6>Business Analyst</h6>

              <Link to={"/new-leads"} className="btn btn-red mb-4">
                New Leads
              </Link>
              <Link to={"/open-leads"} className="btn btn-red btn-bordred mb-4">
                Open Leads
              </Link>
              <Link to={"/search"} className="btn btn-red search mb-4">
                Search
              </Link>
              <Link to={"/diary"} className="btn btn-red blue">
                Diary
              </Link>

              <Col sm="12" className="text-center my-5"><Image src="images/logo.png" className="logo" alt="logo" /></Col>

              <Col sm="12" className="text-center">
              <Link to={"#"}>
                <Image src="images/logout.png" className="logout" alt="logo" />
              </Link>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Home;
