import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

const ResultingIn = () => {
  return (
    <>
      <section className="leads capture-activity">
        <Container fluid>
          <Row>
            <Col sm="12" className="text-center">
              <Col className="d-flex align-items-center mb-4">
                <Link to={"/capture-follow-activity"} className="btn btn-back">
                  <i class="bi bi-chevron-left"></i>
                </Link>
              </Col>
              <ListGroup>
                <ListGroup.Item className="py-3">
                <h4>Resulting in:</h4>
                  <Form className="text-start mb-5">
                    {["radio"].map((type) => (
                      <div key={`inline-${type}`}>
                        <Form.Check
                          label="A sales and delivery"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                        />
                        <Form.Check
                          label="A test drive"
                          name="group1"
                          type={type}
                          id={`inline-${type}-2`}
                        />
                        <Form.Check
                          label="A fromal quote"
                          name="group1"
                          type={type}
                          id={`inline-${type}-3`}
                        />
                        <Form.Check
                          label="Finance application"
                          name="group1"
                          type={type}
                          id={`inline-${type}-4`}
                        />
                        <Form.Check
                          label="Finance approval"
                          name="group1"
                          type={type}
                          id={`inline-${type}-5`}
                        />
                      </div>
                    ))}
                  </Form>
                  <Link to={"/close-lost-deal"} className="btn btn-red mb-3">Close/Lost Deal</Link>
                  <Link to={"/next-contact"} className="btn btn-red blue">Next</Link>

                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ResultingIn;
