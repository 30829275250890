import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

import "react-datepicker/dist/react-datepicker.css";

const NextContact = () => {
  const [startDate, setStartDate] = useState(
    setHours(setMinutes(new Date(), 30), 16)
  );
  return (
    <>
      <section className="leads">
        <Container fluid>
          <Row>
            <Col sm="12">
              <Col className="d-flex align-items-center mb-4">
                <Link to={"/resulting-in"} className="btn btn-back">
                  <i class="bi bi-chevron-left"></i>
                </Link>
                <h5 className="page-title">Next Contact?</h5>
              </Col>

              <Form.Group className="mb-4 mt-5">
                <Form.Label>Date and Time</Form.Label>
                <DatePicker className="form-control"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
                timeFormat="HH:mm"
                injectTimes={[
                  setHours(setMinutes(new Date(), 1), 0),
                  setHours(setMinutes(new Date(), 5), 12),
                  setHours(setMinutes(new Date(), 59), 23),
                ]}
                dateFormat="MMMM d, yyyy h:mm aa"
              />
              </Form.Group>
              
              <Form.Group className="mb-5">
                <Form.Label>Purpose</Form.Label>
                <Form.Select className="form-control">
                  <option selected>-- Please Select --</option>
                  <option value="1">Arrange Delivery date/time</option>
                  <option value="2">Carry out Delivery</option>
                  <option value="3">Arrange test drive</option>
                  <option value="4">Carry out test drive</option>
                  <option value="5">Follow up regarding</option>
                </Form.Select>
              </Form.Group>

              <Link to={"#"} className="btn btn-red mb-3">
                Done/Save
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default NextContact;
