import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";

const Diary = () => {
  return (
    <>
      <section className="diary">
        <Container fluid>
          <Row>
            <Col sm="12" className="text-center">
              <Col className="d-flex align-items-center mb-4">
                <Link to={"/new-leads"} className="btn btn-back">
                  <i class="bi bi-chevron-left"></i>
                </Link>
                <h5 className="page-title">Diary</h5>
              </Col>

              <h6>Scheduled</h6>

              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>Last Contact</th>
                    <th>Customer Name</th>
                    <th>Telephone/Email</th>
                    <th>Purpose of Contact</th>
                    <th>Diary</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>8:00</td>
                    <td>21-Sep-2023</td>
                    <td>Mr Theo Verschuur</td>
                    <td>08234523525</td>
                    <td>Follow up regarding OTP and costing</td>
                    <td>
                      <Link to={"/notes-follow-up"} className="btn">
                        <i class="bi bi-journal-bookmark-fill"></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>8:00</td>
                    <td>21-Sep-2023</td>
                    <td>Mr Theo Verschuur</td>
                    <td>08234523525</td>
                    <td>Follow up regarding OTP and costing</td>
                    <td>
                      <Link to={"/notes-follow-up"} className="btn">
                        <i class="bi bi-journal-bookmark-fill"></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>8:00</td>
                    <td>21-Sep-2023</td>
                    <td>Mr Theo Verschuur</td>
                    <td>08234523525</td>
                    <td>Follow up regarding OTP and costing</td>
                    <td>
                      <Link to={"/notes-follow-up"} className="btn">
                        <i class="bi bi-journal-bookmark-fill"></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>8:00</td>
                    <td>21-Sep-2023</td>
                    <td>Mr Theo Verschuur</td>
                    <td>08234523525</td>
                    <td>Follow up regarding OTP and costing</td>
                    <td>
                      <Link to={"/notes-follow-up"} className="btn">
                        <i class="bi bi-journal-bookmark-fill"></i>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </Table>

              <h6 className="mt-4">Anytime</h6>

              <Table bordered responsive className="mb-4">
                <thead>
                  <tr>
                    <th>Last Contact</th>
                    <th>Customer Name</th>
                    <th>Telephone/Email</th>
                    <th>Purpose of Contact</th>
                    <th>Diary</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>21-Sep-2023</td>
                    <td>Mr Theo Verschuur</td>
                    <td>08234523525</td>
                    <td>Follow up regarding OTP and costing</td>
                    <td>
                      <Link to={"/notes-follow-up"} className="btn">
                        <i class="bi bi-journal-bookmark-fill"></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>21-Sep-2023</td>
                    <td>Mr Theo Verschuur</td>
                    <td>08234523525</td>
                    <td>Follow up regarding OTP and costing</td>
                    <td>
                      <Link to={"/notes-follow-up"} className="btn">
                        <i class="bi bi-journal-bookmark-fill"></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>21-Sep-2023</td>
                    <td>Mr Theo Verschuur</td>
                    <td>08234523525</td>
                    <td>Follow up regarding OTP and costing</td>
                    <td>
                      <Link to={"#"} className="btn">
                        <i class="bi bi-journal-bookmark-fill"></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>21-Sep-2023</td>
                    <td>Mr Theo Verschuur</td>
                    <td>08234523525</td>
                    <td>Follow up regarding OTP and costing</td>
                    <td>
                      <Link to={"#"} className="btn">
                        <i class="bi bi-journal-bookmark-fill"></i>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </Table>

              <h6 className="mt-4">Overdue</h6>

              <Table bordered responsive className="mb-4">
                <thead>
                  <tr>
                    <th>Overdue Contact Date</th>
                    <th>Customer Name</th>
                    <th>New/Used</th>
                    <th>Telephone/Email</th>
                    <th>Purpose of Contact</th>
                    <th>Diary</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>21-Sep-2023</td>
                    <td>Mr Theo Verschuur</td>
                    <td>New</td>
                    <td>08234523525</td>
                    <td>Followup Regarding</td>
                    <td>
                      <Link to={"#"} className="btn">
                        <i class="bi bi-journal-bookmark-fill"></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                  <td>21-Sep-2023</td>
                    <td>Sive Sive</td>
                    <td>New</td>
                    <td>08234523525</td>
                    <td>Telephoned Customer</td>
                    <td>
                      <Link to={"#"} className="btn">
                        <i class="bi bi-journal-bookmark-fill"></i>
                      </Link>
                    </td>
                  </tr>
                 
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Diary;
