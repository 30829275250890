import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";

const NewList = () => {
 
  return (
    <>
      <section className="leads">
        <Container fluid>
          <Row>
            <Col sm="12" className="text-center">
              <Col className="d-flex align-items-center mb-4">
                <Link to={"/home"} className="btn btn-back">
                  <i class="bi bi-chevron-left"></i>
                </Link>
                <h5 className="page-title">New Leads</h5>
              </Col>
              <ListGroup className="leads-list">
                <ListGroup.Item>
                  <h4>Manoj Badage</h4>
                  <p>
                    <i class="bi bi-car-front-fill"></i>
                    <span>
                      <small>Vehicle Make</small>
                      Mercedes-Benz
                    </span>
                  </p>
                  <p>
                    <i class="bi bi-car-front-fill"></i>
                    <span>
                      <small>Vehicle Model</small>
                      503
                    </span>
                  </p>

                  <p className="me-0">
                    <i class="bi bi-file-earmark-text-fill"></i>
                    <span>
                      <small>Vehicle Specifications</small>
                      <ul>
                        <li>20.0 kmpl</li>
                        <li>Diesel</li>
                        <li>Seat: 5</li>
                        <li>Automatic</li>
                      </ul>
                    </span>
                  </p>

                  <Col sm="12" className="text-end">
                    <Link to={"/new-list-details"} className="btn-details">
                      View Details <i class="bi bi-arrow-right"></i>
                    </Link>
                  </Col>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h4>Manoj Badage</h4>
                  <p>
                    <i class="bi bi-car-front-fill"></i>
                    <span>
                      <small>Vehicle Make</small>
                      Mercedes-Benz
                    </span>
                  </p>
                  <p>
                    <i class="bi bi-car-front-fill"></i>
                    <span>
                      <small>Vehicle Model</small>
                      503
                    </span>
                  </p>

                  <p className="me-0">
                    <i class="bi bi-file-earmark-text-fill"></i>
                    <span>
                      <small>Vehicle Specifications</small>
                      <ul>
                        <li>20.0 kmpl</li>
                        <li>Diesel</li>
                        <li>Seat: 5</li>
                        <li>Automatic</li>
                      </ul>
                    </span>
                  </p>
                  <Col sm="12" className="text-end">
                    <Link to={"/new-list-details"} className="btn-details">
                      View Details <i class="bi bi-arrow-right"></i>
                    </Link>
                  </Col>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h4>Manoj Badage</h4>
                  <p>
                    <i class="bi bi-car-front-fill"></i>
                    <span>
                      <small>Vehicle Make</small>
                      Mercedes-Benz
                    </span>
                  </p>
                  <p>
                    <i class="bi bi-car-front-fill"></i>
                    <span>
                      <small>Vehicle Model</small>
                      503
                    </span>
                  </p>

                  <p className="me-0">
                    <i class="bi bi-file-earmark-text-fill"></i>
                    <span>
                      <small>Vehicle Specifications</small>
                      <ul>
                        <li>20.0 kmpl</li>
                        <li>Diesel</li>
                        <li>Seat: 5</li>
                        <li>Automatic</li>
                      </ul>
                    </span>
                  </p>
                  <Col sm="12" className="text-end">
                    <Link to={"/new-list-details"} className="btn-details">
                      View Details <i class="bi bi-arrow-right"></i>
                    </Link>
                  </Col>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h4>Manoj Badage</h4>
                  <p>
                    <i class="bi bi-car-front-fill"></i>
                    <span>
                      <small>Vehicle Make</small>
                      Mercedes-Benz
                    </span>
                  </p>
                  <p>
                    <i class="bi bi-car-front-fill"></i>
                    <span>
                      <small>Vehicle Model</small>
                      503
                    </span>
                  </p>

                  <p className="me-0">
                    <i class="bi bi-file-earmark-text-fill"></i>
                    <span>
                      <small>Vehicle Specifications</small>
                      <ul>
                        <li>20.0 kmpl</li>
                        <li>Diesel</li>
                        <li>Seat: 5</li>
                        <li>Automatic</li>
                      </ul>
                    </span>
                  </p>
                  <Col sm="12" className="text-end">
                    <Link to={"/new-list-details"} className="btn-details">
                      View Details <i class="bi bi-arrow-right"></i>
                    </Link>
                  </Col>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h4>Manoj Badage</h4>
                  <p>
                    <i class="bi bi-car-front-fill"></i>
                    <span>
                      <small>Vehicle Make</small>
                      Mercedes-Benz
                    </span>
                  </p>
                  <p>
                    <i class="bi bi-car-front-fill"></i>
                    <span>
                      <small>Vehicle Model</small>
                      503
                    </span>
                  </p>

                  <p className="me-0">
                    <i class="bi bi-file-earmark-text-fill"></i>
                    <span>
                      <small>Vehicle Specifications</small>
                      <ul>
                        <li>20.0 kmpl</li>
                        <li>Diesel</li>
                        <li>Seat: 5</li>
                        <li>Automatic</li>
                      </ul>
                    </span>
                  </p>
                  <Col sm="12" className="text-end">
                    <Link to={"/new-list-details"} className="btn-details">
                      View Details <i class="bi bi-arrow-right"></i>
                    </Link>
                  </Col>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default NewList;
