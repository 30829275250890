import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';

const NewListDetails = () => {
  return (
    <>
      <section className="leads leads-details">
        <Container fluid>
          <Row>
            <Col sm="12" className="text-center">
              <Col className="d-flex align-items-center mb-4">
                <Link to={"/new-leads"} className="btn btn-back">
                  <i class="bi bi-chevron-left"></i>
                </Link>
                <h5 className="page-title">Leads Details</h5>
              </Col>
              <ListGroup className="leads-list">
                <ListGroup.Item>
                  <small className="date_time">
                    Date/Time received: HH:MM YY:MM:DD
                  </small>
                  <h4>Manoj Badage</h4>
                  <p>
                    <i class="bi bi-phone-fill"></i>
                    <span>
                      <small>Mobile No.</small>
                      +91 9011509700
                    </span>
                  </p>
                  <p>
                    <i class="bi bi-envelope-fill"></i>
                    <span>
                      <small>Email</small>
                      manoj@appristine.in
                    </span>
                  </p>
                  <p>
                    <i class="bi bi-car-front-fill"></i>
                    <span>
                      <small>Vehicle Make</small>
                      Mercedes-Benz
                    </span>
                  </p>
                  <p>
                    <i class="bi bi-car-front-fill"></i>
                    <span>
                      <small>Vehicle Model</small>
                      503
                    </span>
                  </p>

                  <p className="me-0">
                    <i class="bi bi-file-earmark-text-fill"></i>
                    <span>
                      <small>Vehicle Specifications</small>
                      <ul>
                        <li>20.0 kmpl</li>
                        <li>Diesel</li>
                        <li>Seat: 5</li>
                        <li>Automatic</li>
                      </ul>
                    </span>
                  </p>
                  <p>
                    <i class="bi bi-car-front-fill"></i>
                    <span>
                      <small>Lead Source</small>
                      SOE
                    </span>
                  </p>
                </ListGroup.Item>
                <ListGroup.Item className="notes">
                  <Col sm="12">
                    <Form>
                      <Form.Group>
                        <Form.Label>Notes</Form.Label>
                        <Form.Control as="textarea" rows={4} />
                      </Form.Group>
                      <Button variant="primary" type="submit" className="btn-add">
                        Add Note
                      </Button>
                    </Form>
                  </Col>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link to={"#"} className="btn btn-red mb-3">
                    Accept Lead
                  </Link>
                  <Link to={"/capture-follow-activity"} className="btn btn-red blue mb-3">
                    Capture Follow Up Activity
                  </Link>
                  <Link to={"#"} className="btn btn-red green">
                    <i class="bi bi-telephone"></i> Call Customer
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default NewListDetails;
