import "./App.css";
import {BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import axios from 'axios';
import LoginPage from "./components/login/LoginPage";
import Home from "./components/home/Home";
import NewList from "./components/new-leads/NewList";
import NewListDetails from "./components/new-leads/NewListDetails";
import CaptureFollowActivity from "./components/new-leads/CaptureFollowActivity";
import ResultingIn from "./components/new-leads/ResultingIn";
import NextContact from "./components/new-leads/NextContact";
import CloseLostDeal from "./components/new-leads/CloseLostDeal";
import Search from "./components/search/Search";
import Diary from "./components/diary/Diary";
import NotesFollowUp from "./components/diary/NotesFollowUp";
import OpenLeads from "./components/open-leads/OpenLeads";
import OpenLeadsDetails from "./components/open-leads/OpenLeadsDetails";
import { useEffect } from "react";
// import { GET_LEADS_API_URL } from "./components/Shared/constant";
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

function App() {

  useEffect(() => {
    GetApi();
  }, []);

  const GetApi = async () => {
    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/xml" 
     }
     
     let bodyContent = `<Params>
         <SecurityData><AccessCode>1E82689HJH66446fgGHHJGJH66642A26E</AccessCode><DealerCode>1011</DealerCode></SecurityData>
         <XMLData><InputParams><NewLeadsOnly>0</NewLeadsOnly><StartDate>2023-10-01 00:00:00</StartDate><EndDate>2023-10-31 23:59:59</EndDate></InputParams></XMLData>
     </Params>`;
     
     let reqOptions = {
       url: "https://api.autoslm.com/GetLeads/",
       method: "GET",
       headers: headersList,
       data: bodyContent,
     }
     
     let response = await axios.request(reqOptions);
     console.log(response.data);
  };

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage/>}/> 
          <Route path="/home" element={<Home/>}/> 
          <Route path="/new-leads" element={<NewList/>}/> 
          <Route path="/new-list-details" element={<NewListDetails/>}/> 
          <Route path="/capture-follow-activity" element={<CaptureFollowActivity/>}/> 
          <Route path="/resulting-in" element={<ResultingIn/>}/> 
          <Route path="/next-contact" element={<NextContact/>}/> 
          <Route path="/close-lost-deal" element={<CloseLostDeal/>}/>
          <Route path="/search" element={<Search/>}/> 
          <Route path="/diary" element={<Diary/>}/> 
          <Route path="/notes-follow-up" element={<NotesFollowUp/>}/> 

          <Route path="/open-leads" element={<OpenLeads/>}/> 
          <Route path="/open-leads-details" element={<OpenLeadsDetails/>}/> 
          
          
        </Routes>
        
      </BrowserRouter>
    </>
  );
}

export default App;
